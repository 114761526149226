<template>
  <div class="home">
    <Header></Header>

    <div class="skills-main-box">
      <!-- 轮播图 -->
      <!-- Swiper -->
      <div class="swiper-container skills-main-swiper">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in swiperDataArray"
            :key="index+'s'"
            @click="goSkillsMsg(item.id)"
          >
            <img
              v-if="item.positionList[0]"
              :src="item.positionList[0].assetList[0].asset.url"
            />
            <div class="text-main-box">
              <div class="main-box-rel">
                <p class="p1">{{item.positionList[4].assetList[0].asset.baseText}}</p>
                <p class="p2">{{item.positionList[5].assetList[0].asset.baseText}}</p>
              </div>
            </div>
            
          </div>
        </div>
        <!-- Add Pagination -->
        <div class="swiper-pagination"></div>
        <div class="swiper-button-prev"></div>
        <!--左箭头。如果放置在swiper外面，需要自定义样式。-->
        <div class="swiper-button-next"></div>
        <!--右箭头。如果放置在swiper外面，需要自定义样式。-->
      </div>
      <div class="skills-banner-box">
        <p class="big-p">ZUI 使用技巧</p>
        <div class="select-box">
          <div class="select-box-rel">
            <div class="left-img" @click="goSkillsSel">
              <img src="../assets/img/skills-search.png" alt="" />
            </div>
            <div class="right-input">
              <input
                type="text"
                v-model.trim="searchText"
                @keyup.enter="selectFn"
                placeholder="搜索"
                class="input"
              />
            </div>
          </div>
        </div>
        <div class="slide-ul">
          <div class="slide-box">
            <div
              :class="[
                activeNum == index ? 'slide-li slide-li-active' : 'slide-li',
              ]"
              v-for="(item, index) in twoNavigators"
              :key="index"
              @click="slideFn(index, item.value)"
            >
              <div class="top-img">
                <img
                  v-if="index == 0"
                  src="../assets/img/skills-logo2.png"
                  style="width: 0.52rem; top: 0.08rem"
                  class="img1"
                  alt=""
                />
                <img
                  v-if="index == 0"
                  src="../assets/img/skills-logo22.png"
                  style="width: 0.52rem; top: 0.08rem"
                  class="img2"
                  alt=""
                />
                <img
                  v-if="index == 1"
                  src="../assets/img/skills-logo3.png"
                  style="width: 0.49rem"
                  class="img1"
                  alt=""
                />
                <img
                  v-if="index == 1"
                  src="../assets/img/skills-logo32.png"
                  style="width: 0.49rem"
                  class="img2"
                  alt=""
                />
                <img
                  v-if="index == 2"
                  src="../assets/img/skills-logo1.png"
                  style="width: 0.53rem; top: 0.1rem"
                  class="img1"
                  alt=""
                />
                <img
                  v-if="index == 2"
                  src="../assets/img/skills-logo12.png"
                  style="width: 0.53rem; top: 0.1rem"
                  class="img2"
                  alt=""
                />
                <img
                  v-if="index == 3"
                  src="../assets/img/skills-logo4.png"
                  style="width: 0.47rem"
                  class="img1"
                  alt=""
                />
                <img
                  v-if="index == 3"
                  src="../assets/img/skills-logo42.png"
                  style="width: 0.47rem"
                  class="img2"
                  alt=""
                />
              </div>
              <p class="bottom-p">{{ item.name }}</p>
            </div>

            <div :class="['move-line move-line' + activeNum]"></div>
          </div>
        </div>
      </div>
      <div class="skills-content">
        <div class="content-select-input">
          <div class="left-text">最新技巧</div>
          <div class="select-edition-box">
            <div class="top-txt-box">
              
              <img class="img" src="../assets/img/xia-jiantou.png" alt="">
              <p class="txt" v-if="editionSelectObj.childList">{{editionSelectObj.childList[couponSelected].name}}</p>
            </div>
            <div class="edition-box">
              <div class="edition-box-rel">
                  <p :class="[couponSelected==index?'txt-p txt-p-active':'txt-p']" @click="slideFn2(item,index)" v-for="(item,index) in editionSelectObj.childList" :key="index">{{item.name}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="content-box" v-if='allMsg.length>0'>
          <div
            class="content-slide"
            v-for="item in allMsg"
            :key="item.id"
            @click="goSkillsMsg(item.id)"
          >
            <div class="top-box-video">
              <div class="box-text">
                <img
                  v-if="item.positionList[0]"
                  :src="item.positionList[0].assetList[0].asset.url"
                />
              </div>
              <div class="block-bg">
                <div class="block-bg-rel">
                  <div class="video-logo">
                    <img src="../assets/img/skills-video-logo.png" alt="" />
                  </div>
                  <!-- <div class="time-p">00:46</div> -->
                </div>
              </div>
            </div>
            <div class="bottom-text-main">
              <div class="top-logo-text">
                <p
                  class="name-logo"
                  v-if="item.extContentInfo.a1b8c36854b145e8902683d8ddb12739"
                >
                  {{ item.extContentInfo.a1b8c36854b145e8902683d8ddb12739[0].title }}
                </p>
                <p class="time">{{ timestampToTime(item.publishTime) }}</p>
              </div>
              <p class="big-p" v-if="item.positionList[1].assetList[0]">
                {{ item.positionList[1].assetList[0].asset.baseText }}
              </p>
              <p class="two-p" v-if="item.positionList[1].assetList[0]">
                {{ item.positionList[2].assetList[0].asset.baseText }}
              </p>
              <p class="big-p">支持机型</p>
              <div class="about-slide">
                <div
                  class="slide"
                  v-for="(item2,index3) in item.extContentInfo
                    .CUSTOMER_PORTAL_PRODUCT_NUMBER"
                  :key="item2.value"
                >
                <span v-if="index3==0" @click.stop="selectFn2(item2.title)">
                    {{ index3==1?"......":item2.title }}
                </span>
                <span v-else-if="index3<2">
                    {{ index3==1?"......":item2.title }}
                </span>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="see-more" v-if="allDataNum > 1&&this.pageNum!=allDataNum" @click="getMoreMsg">查看更多</div>

        <div class="product05-content product05-content2" v-if="allMsg.length<1&&!testModelStatus">
          	<img class="no-msg" src="../assets/img/loading.png" alt="">
        </div>
        <div class="product05-content product05-content2" v-else-if="allMsg.length<1&&testModelStatus">
          	<img class="no-msg2" src="../assets/img/zanwushuju.png" alt="">
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
//设置html font-size
document.documentElement.style.fontSize =
  document.documentElement.clientWidth / 38.4 + "px";
window.onresize = () => {
  console.log(111)
  document.documentElement.style.fontSize =
    document.documentElement.clientWidth / 38.4 + "px";
};
/* 
var blockIdArr={
    335 最新消息Banner
    327 最新消息>内容详情
    362 更新信息>机型图
    334 更新记录>内容详情
    333 内测机型Banner
    366 内侧机型>机型列表
    363 二维码-微信
    364 二维码-微博
    indexBanner:335,
    newMsg:327,
    newsjixing:362,
    update:334,
    neicebanner:333,
    neicejixing:366,
    vxcode:363,
    wbcode:364
}
*/
//接口地址引入
import interfaces from "../utils/baseUrl";
import instance from "../utils/http";
import axios from "axios";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Swiper from "swiper";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      twoNavigators: [
      ],
      twoNavigatorsCode: "",
      //选中索引
      activeNum: 0,
      //所有新闻
      allMsg: [],
      //首页banner
      bannerdata: "",
      //最新消息列表
      newMsg: [],
      //更新信息banner
      updatedata: [{ coverPicture: "" }],
      rightMoreArr: [],
      bannerUrl: "",
      pageNum: 1,
      pageSize: 8,
      searchText: "",
      leftNavList: [],
      leftNavListStatus: false,
      showIndex: 0,
      //左侧导航code
      leftCode: "",
      leftValue: "",
      navigators: [],
      allNavigators: [],
      allDataNum: "",
      //轮播swiper
      swiperDataArray:[],
      twoNavigatorsValue:'',
      editionSelectObj:[],
      couponSelected:0,
      couponSelectedValue:'',
      testModelStatus:false,
      editionIndex:0,
    };
  },
  beforeMount: function () {
    this.getBaseInfo2();
    this.getContent();
  },
  //初始化执行
  created: function () {
    let $this = this;
    // $this.getBaseInfo(335)
  },
  mounted() {
    //   this.myswiper();
  },
  //事件
  methods: {
    getBannerContent(code, value){
        var data;
         data = {
            token: "",
            param: {
              blockId: interfaces.blockIdArr.skillBlockID,
              filterConditionList: [],
              pageNum: this.pageNum,
              pageSize: this.pageSize,
              filterConditionList: [
                {
                  code: code,
                  value: [value],
                  type: 4,
                }
              ],
            },
          };
           instance
        .post(`/open-api/standard/v1/searchContent`, data, {
          headers: {
            "Content-Type": "application/json",
            "Token-type": "bearer",
          },
        })
        .then((res) => {
          // //console.log(res);
          if (res&&res.data.result) {
            this.swiperDataArray= res.data.result.records.filter((item,index)=>{
              return index<3
            });
            this.$nextTick(() => {
              this.myswiper();
            });
          }
        });
    },
    getContent(code, value,keywords,more) {
      var data;
      
      if (code && value) {
        if(keywords){
          data = {
            token: "",
            param: {
              blockId: interfaces.blockIdArr.skillBlockID,
              filterConditionList: [],
              pageNum: this.pageNum,
              pageSize: this.pageSize,
              keywords:keywords,
              filterConditionList: [
                {
                  code: code,
                  value: [value],
                  type: 4,
                }
              ],
            },
          };
        }else{
          data = {
            token: "",
            param: {
              blockId: interfaces.blockIdArr.skillBlockID,
              filterConditionList: [],
              pageNum: this.pageNum,
              pageSize: this.pageSize,
              filterConditionList: [
                {
                  code: code,
                  value: [value],
                  type: 4,
                }
              ],
            },
          };
        }
        
      } else {
        if(keywords){
          data = {
            token: "",
            
            param: {
              blockId: interfaces.blockIdArr.skillBlockID,
              pageNum: this.pageNum,
              keywords:keywords,
              pageSize: this.pageSize,
            },
          };
        }else{
          data = {
            token: "",
            param: {
              blockId: interfaces.blockIdArr.skillBlockID,
              pageNum: this.pageNum,
              pageSize: this.pageSize,
            },
          };
        }
        
      }

      instance
        .post(`/open-api/standard/v1/searchContent`, data, {
          headers: {
            "Content-Type": "application/json",
            "Token-type": "bearer",
          },
        })
        .then((res) => {
          // //console.log(res);
          if (res&&res.data.result) {
            this.allDataNum = res.data.result.totalPage;
            if(more){
              this.allMsg =this.allMsg.concat(res.data.result.records);
            }else{
              this.allMsg = res.data.result.records;
            }
            
            this.testModelStatus=true;
            if(code){
                
            }else{
              //  //console.log(1111)
                // this.swiperDataArray= res.data.result.records.filter((item,index)=>{
                //   return index<3
                // });
            }
            this.$nextTick(() => {
              this.myswiper();
            });
          }else{
              instance
                .post(`/open-api/standard/v1/searchContent`, data, {
                    headers: {
                        "Content-Type": "application/json",
                        "Token-type": "bearer",
                    },
                })
                .then((res) => {
                    //console.log(res);
                    if (res&&res.data.result) {
                        this.allDataNum = res.data.result.totalPage;
                         if(more){
                          this.allMsg =this.allMsg.concat(res.data.result.records);
                        }else{
                          this.allMsg = res.data.result.records;
                        }
                        this.testModelStatus=true;
                        if(code){

                        }else{
                           //console.log(2222)
                            // this.swiperDataArray= res.data.result.records.filter((item,index)=>{
                            //   return index<3
                            // });
                        }
                        this.$nextTick(() => {
                            this.myswiper();
                        });
                    }
                });
          }
        });
    },
    getBaseInfo2(data) {
      var $this = this;
      var data = {
        token: "",
        param: {
          blockId: interfaces.blockIdArr.skillBlockID,
        },
      };
      instance
        .post(`/open-api/standard/v1/getPublishSetting`, data, {
          headers: {
            "Content-Type": "application/json",
            "Token-type": "bearer",
          },
        })
        .then((res) => {
          
          if(res&&res.data.result){
            $this.twoNavigatorsCode = res.data.result[0].code;
            $this.twoNavigators = res.data.result[0].childList;
            $this.editionSelectObj=res.data.result[3];
            $this.twoNavigators.unshift({
                childList: null,
                key: "",
                name: "全部",
                value: "",
            });
            $this.twoNavigators = $this.twoNavigators.filter((item) => {
                return item.name != "无";
            });
            $this.editionSelectObj.childList.unshift({
                childList: null,
                key: "",
                name: "全部",
                value: "",
            });
            $this.editionSelectObj.childList = $this.editionSelectObj.childList.filter((item) => {
                return item.name != "无";
            });
            $this.getBannerContent(res.data.result[1].code,res.data.result[1].childList[1].value)
          }else{
              
                instance.post(`/open-api/standard/v1/getPublishSetting`, data, {
                headers: {
                    "Content-Type": "application/json",
                    "Token-type": "bearer",
                },
                })
                .then((res) => {
                
                if(res&&res.data.result){
                    $this.twoNavigatorsCode = res.data.result[0].code;
                    $this.twoNavigators = res.data.result[0].childList;
                    $this.twoNavigators.unshift({
                        childList: null,
                        key: "",
                        name: "全部",
                        value: "",
                    });
                    $this.twoNavigators = $this.twoNavigators.filter((item) => {
                        return item.name != "无";
                    });
                    $this.getBannerContent(res.data.result[1].code,res.data.result[1].childList[1].value)
                }else{
                    
                }
                
                });
          }
          
        });
    },
    //获取导航
    getBaseInfo(id) {
      var $this = this;
      var config = {
        method: "get",
        url: `${interfaces.baseUrl}/portal/open/block/public/zui/getBaseInfo/${id}`,
        headers: interfaces.headers(),
      };

      axios(config)
        .then(function (response) {
          //console.log(response);
        })
        .catch(function (error) {
          return error;
        });
    },
    getAllSkillsMsg(data) {
      var $this = this;
      var config = {
        method: "post",
        url: `${interfaces.baseUrl}/portal/open/block/search`,
        headers: interfaces.postHeaders(),
        data,
      };

      axios(config)
        .then(function (response) {
          $this.rightMoreArr = response.data.result;
        })
        .catch(function (error) {
          return error;
        });
    },
    goSkillsMsg(id) {
      //console.log(id);
      this.$router.push({
        name: "killsMessage",
        query: {
          id: id,
        },
      });
    },
    goSkillsSel() {
     this.$router.push({
        name: "skillsSelect",
        query: {
          searchText: this.searchText,
        },
      });
    },
    goSkillsSel2(name,index) {
     this.searchText=name;
     this.editionIndex=index
     this.$router.push({
        name: "skillsSelect",
        query: {
          searchText: this.searchText,
        },
      });
    },
    slideFn(index, value) {
      this.activeNum = index;
      //console.log(value);
      this.twoNavigatorsValue=value;
      this.pageNum = 1;
      this.couponSelected=0;
      this.getContent(this.twoNavigatorsCode, value);
    },
    slideFn2(e,index) {
      this.pageNum = 1;
      // this.getContent(code, value);
      this.couponSelected=index;
      var code=this.editionSelectObj.code
      var value=this.couponSelected
      this.couponSelectedValue=this.editionSelectObj.childList[this.couponSelected].name;
      if(this.couponSelectedValue=='全部'){
          this.getContent(this.twoNavigatorsCode, this.twoNavigatorsValue);
      }else{
        this.getContent(this.twoNavigatorsCode, this.twoNavigatorsValue,this.couponSelectedValue);
      }
      
      //console.log(code)
      //console.log(value)
    },
    getMoreMsg(){
      this.pageNum=this.pageNum + 1;
      //console.log()
      this.getContent(this.twoNavigatorsCode, this.twoNavigatorsValue,this.couponSelectedValue,'more');
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = date.getDate() + " ";
      return Y + M + D;
    },
    myswiper() {
      var swiper = new Swiper(".skills-main-swiper", {
        pagination: {
          el: ".swiper-pagination",
          type: "fraction",
        },
        slidesPerView: 1.7,
        spaceBetween: (document.documentElement.clientWidth / 38.4)*.25,
        loop: true,
        slidesOffsetBefore: (document.documentElement.clientWidth / 38.4) * 7.92,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
    selectFn() {
        //console.log(this.searchText)
      this.$router.push({
        name: "skillsSelect",
        query: {
          searchText: this.searchText,
        },
      });
    },
    selectFn2(item) {
        //console.log(this.searchText)
      this.$router.push({
        name: "skillsSelect",
        query: {
          searchText: item,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import url("../../node_modules/swiper/css/swiper.css");
.no-msg2{
  width: 180px !important;
  margin: 0 auto;
  margin-top: 75px;
  padding-bottom: 75px;
}
.no-msg2 img{
  width: 100%;
}
.product05-content{
	width: 1200px;
	margin: 0 auto;
	overflow: hidden;
}
.product05-content2{
  margin-top: 40px;
  background: #F5F5F5;
}
.skills-main-box {
  width: 100%;
  min-height: calc(100vh - 3rem);
  overflow: hidden;
  background: #ffffff;
  position: relative;
  padding-top: 0.95rem;
  box-sizing: border-box;
}
.skills-main-box img {
  display: block;
  width: 100%;
}
.skills-main-box .skills-banner-box {
  position: relative;
  width: 100%;
  height: 5.6rem;
  background: #f5f5f6;
  overflow: hidden;
}
.skills-main-box .skills-banner-box .big-p{
    position:absolute;
    top: .45rem;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: .7rem;
    color: #000000;
    font-weight: 600;
}
.skills-main-box .skills-banner-box .top-tit-a {
  font-size: 0.24rem;
  color: #000000;
  overflow: hidden;
  display: flex;
  position: absolute;
  top: 0.6rem;
  left: 7.2rem;
}
.skills-main-box .skills-banner-box .top-tit-a .p1 {
  font-size: 0.24rem;
  color: #000000;
}
.skills-main-box .skills-banner-box .top-tit-a .shu {
  display: inline-block;
  padding: 0 0.2rem;
  font-size: 0.24rem;
  color: #000000;
}
.skills-main-box .skills-banner-box .big-tit {
  position: absolute;
  top: 1.45rem;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 1rem;
  color: #000000;
  font-weight: 600;
}
.skills-main-box .skills-banner-box .slide-ul {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 1.5rem;
  display: flex;
  justify-content: center;
}
.skills-main-box .skills-banner-box .slide-ul .slide-box {
  width: auto;
  margin: 0 auto;
  overflow: hidden;
  height: 100%;
  position: relative;
}
.skills-main-box .skills-banner-box .slide-ul .slide-box .slide-li {
  float: left;
  width: 1.9rem;
  height: 100%;
  margin-left: 1rem;
  cursor: pointer;
}
.skills-main-box
  .skills-banner-box
  .slide-ul
  .slide-box
  .slide-li:nth-of-type(1) {
  margin-left: 0;
}
.skills-main-box .skills-banner-box .slide-ul .slide-box .slide-li .top-img {
  width: 100%;
  height: 0.8rem;
  overflow: hidden;
  position: relative;
}
.skills-main-box
  .skills-banner-box
  .slide-ul
  .slide-box
  .slide-li
  .top-img
  img {
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}
.skills-main-box
  .skills-banner-box
  .slide-ul
  .slide-box
  .slide-li
  .top-img
  .img1 {
  display: block;
}
.skills-main-box
  .skills-banner-box
  .slide-ul
  .slide-box
  .slide-li
  .top-img
  .img2 {
  display: none;
}
.skills-main-box
  .skills-banner-box
  .slide-ul
  .slide-box
  .slide-li-active
  .top-img
  .img1 {
  display: none;
}
.skills-main-box
  .skills-banner-box
  .slide-ul
  .slide-box
  .slide-li-active
  .top-img
  .img2 {
  display: block;
}
.skills-main-box .skills-banner-box .slide-ul .slide-box .slide-li .bottom-p {
  width: 100%;
  text-align: center;
  font-size: 0.3rem;
  color: #999999;
}
.skills-main-box
  .skills-banner-box
  .slide-ul
  .slide-box
  .slide-li-active
  .bottom-p {
  color: #000000;
}
.skills-main-box .skills-banner-box .slide-ul .slide-box .move-line {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 1.9rem;
  height: 0.1rem;
  background: #0d0c22;
}
.skills-main-box .skills-banner-box .slide-ul .slide-box .move-line1 {
  left: 2.95rem;
}
.skills-main-box .skills-banner-box .slide-ul .slide-box .move-line2 {
  left: 5.75rem;
}
.skills-main-box .skills-banner-box .slide-ul .slide-box .move-line3 {
  left: 8.64rem;
}
.no-msg {
  width: 180px !important;
  margin: 0 auto;
  margin-top: 75px;
  padding-bottom: 75px;
}
.no-msg img {
  width: 100%;
}

.skills-content {
  padding: 1.5rem 0;
  width: 1200px;
  margin: 0 auto;
  overflow: hidden;
}
.skills-content .content-select-input {
  // overflow: hidden;
}
.skills-content .content-select-input .select-edition-box{
  border: none;
  font-size: 15px;
  float: right;
  width:134px;
  height:40px;
  line-height: 40px;
  position: relative;
  padding:0 8px 0 20px;
  box-sizing: border-box;
}
.skills-content .content-select-input .select-edition-box .top-txt-box{
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.skills-content .content-select-input .select-edition-box .top-txt-box .txt{
  float: right;
  margin-right: 10px;
}
.skills-content .content-select-input .select-edition-box .top-txt-box .img{
  float: right;
  width:14px;
  margin: 16px 0 0 5px;
}
.skills-content .content-select-input .select-edition-box .edition-box{
  position: absolute;
  top: 30px;
  left:0;
  width: 100%;
  z-index: 2;
  display: none;
}
.skills-content .content-select-input .select-edition-box:hover .edition-box{
  display: block;
}
.skills-content .content-select-input .select-edition-box .edition-box-rel{
  width: 100%;
  padding-top:9px;
  box-sizing: border-box;
  z-index: 2;
  position: relative;
  background: url(../assets/img/border-bg.png) no-repeat;
  background-size:100%;
  -webkit-background-size:100%;
  padding:9px 1px 1px 1px;
  overflow: hidden;
  // overflow: hidden;
}
.skills-content .content-select-input .select-edition-box .edition-box-rel .txt-p{
  width: 100%;
  text-align: center;
  height:40px;
  line-height: 40px;
  font-size: 14px;
  border-bottom: 1px solid #e6e6e6;
  cursor: pointer;
}
.skills-content .content-select-input .select-edition-box .edition-box-rel .txt-p:hover{
  background: #f6f6f6;
  color: #7170fc;
}
.skills-content .content-select-input .select-edition-box .edition-box-rel .txt-p:nth-of-type(1){
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
}
.skills-content .content-select-input .select-edition-box .edition-box-rel .txt-p:last-of-type{
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}
.skills-content .content-select-input .select-edition-box .edition-box-rel .txt-p-active{
  background: #f6f6f6;
  color: #7170fc;
}
.skills-content .content-select-input .left-text {
  float: left;
  font-size: 26px;
  color: #000000;
  font-weight: 600;
}
.skills-content .content-box {
  width: 100%;
  overflow: hidden;
}
.skills-content .content-box .content-slide {
  float: left;
  width: 280px;
  height: 330px;
  margin-left: 25px;
  margin-top: 26px;
  border-radius: 10px;
  background: #f5f5f6;
  cursor: pointer;
  overflow: hidden;
  padding-bottom: 10px;
  box-sizing: border-box;
  border: 2px solid #f5f5f6;
}
.skills-content .content-box .content-slide:nth-of-type(4n + 1) {
  margin-left: 0;
}
.skills-content .content-box .content-slide .top-box-video {
  position: relative;
  width: 100%;
  background: #ebebed;
}
.skills-content .content-box .content-slide .top-box-video .box-text {
  width: 100%;
  text-align: center;
  overflow: hidden;
}
.skills-content .content-box .content-slide .top-box-video .box-text img {
  width: 100%;
}
.skills-content .content-box .content-slide .top-box-video .box-text .p1 {
  font-size: 24px;
  color: #7a6afb;
}
.skills-content .content-box .content-slide .top-box-video .box-text .p2 {
  font-size: 24px;
  color: #000000;
}
.skills-content .content-box .content-slide .top-box-video .block-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: none;
}
.skills-content .content-box .content-slide:hover .top-box-video .block-bg {
  display: block;
}
.skills-content
  .content-box
  .content-slide
  .top-box-video
  .block-bg
  .block-bg-rel {
  position: relative;
  width: 100%;
  height: 100%;
}
.skills-content
  .content-box
  .content-slide
  .top-box-video
  .block-bg
  .block-bg-rel
  .video-logo {
  position: absolute;
  top: 110px;
  left: 15px;
  width: 40px;
  height: 40px;
}
.skills-content
  .content-box
  .content-slide
  .top-box-video
  .block-bg
  .block-bg-rel
  .time-p {
  position: absolute;
  top: 121px;
  left: 63px;
  color: #ffffff;
  font-size: 15px;
}
.skills-content .content-box .content-slide .bottom-text-main {
  width: 100%;
  padding: 10px 15px;
  overflow: hidden;
  box-sizing: border-box;
}
.skills-content .content-box .content-slide .bottom-text-main .top-logo-text {
  width: 100%;
  overflow: hidden;
}
.skills-content
  .content-box
  .content-slide
  .bottom-text-main
  .top-logo-text
  .name-logo {
  float: left;
  padding: 4px 8px;
  border-radius: 4px;
  background: #5f7bfd;
  font-size: 12px;
  color: #ffffff;
}
.skills-content
  .content-box
  .content-slide
  .bottom-text-main
  .top-logo-text
  .time {
  float: left;
  margin-left: 6px;
  line-height: 24px;
  font-size: 12px;
  color: #999999;
}
.skills-content .content-box .content-slide .bottom-text-main .big-p {
  margin-top: 13px;
  font-size: 13px;
  height:17px;
  font-weight: 600;
  color: #000000;
  overflow:hidden;
  text-overflow:ellipsis;
  overflow:hidden;
  text-overflow:ellipsis;
   -o-text-overflow:ellipsis;
  white-space:nowrap;
}
.skills-content .content-box .content-slide .bottom-text-main .two-p {
  margin-top: 9px;
  color: #666666;
  font-size: 10px;
  height:17px;
  width: 100%;
  overflow:hidden;
  text-overflow:ellipsis;
  overflow:hidden;
  text-overflow:ellipsis;
   -o-text-overflow:ellipsis;
  white-space:nowrap;
}
.skills-content .content-box .content-slide .bottom-text-main .about-slide {
  overflow: hidden;
  margin-top: 10px;
  height: 28px;
  display: flex;   /* flex布局 */
  justify-content: flex-start;   /* 左对齐 */
  text-overflow: ellipsis;
  white-space: nowrap;
}
.skills-content
  .content-box
  .content-slide
  .bottom-text-main
  .about-slide
  .slide {
  float: left;
  margin-left: 3%;

}
.skills-content
  .content-box
  .content-slide
  .bottom-text-main
  .about-slide
  .slide span{
    padding: 3px 10px;
    height: 28px;
    line-height: 25px;
    text-align: center;
    box-sizing: border-box;
    box-sizing: border-box;
    background: #ffffff;
    font-size: 10px;
    color: #666666;
  }
.skills-content
  .content-box
  .content-slide
  .bottom-text-main
  .about-slide
  .slide:nth-of-type(3n + 1) {
  margin-left: 0;
}
.skills-content .see-more {
  margin: 0 auto;
  margin-top: 50px;
  width: 78px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  font-size: 12px;
  color: #000000;
  border: 1px solid #000000;
  border-radius: 4px;
  cursor: pointer;
}
.skills-main-swiper {
  // width: 100%;
  // height:13.5rem;
  margin-top: 0.2rem;
}
.skills-main-swiper .swiper-slide {
  width: 24rem;
  height:10.2rem;
  overflow: hidden;
  position: relative;
}
.skills-main-swiper .text-main-box{
  position:absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
}
.skills-main-swiper .text-main-box .main-box-rel{
  position: relative;
  width: 100%;
  height: 100%;
}
.skills-main-swiper .swiper-slide img{
  position: relative;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.skills-main-swiper .swiper-slide .p1{
  position: absolute;
  bottom: 2rem;
  left: .8rem;
  color: #fff;
  font-size: .4rem;
   text-shadow:2px 1px #000;
   letter-spacing: 2px;
}
.skills-main-swiper .swiper-slide .p2{
  position: absolute;
  bottom: 1.2rem;
  left: .8rem;
  color: #fff;
  font-size: .6rem;
  font-weight: 600;
  //  mix-blend-mode: difference;
  text-shadow:2px 1px #000;
  letter-spacing: 2px;
}
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  left: 28.4rem;
  width: 1rem;
  height: 0.6rem;
  line-height: 0.6rem;
  text-align: center;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 0.1rem;
  color: #ffffff;
}
.swiper-container{
    background: #f5f5f6;
}
.swiper-pagination-current {
  color: #ffffff;
}
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  content: "";
}
.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  content: "";
}
.swiper-button-next {
  width: 1rem;
  height: 1rem;
  background: url("../assets/img/next-logo.png");
  background-size: 100%;
  -webkit-background-size: 100%;
}
.swiper-button-prev {
  width: 1rem;
  height: 1rem;
  background: url("../assets/img/prov-logo.png");
  background-size: 100%;
  -webkit-background-size: 100%;
}
.skills-main-box .skills-banner-box .select-box {
  position: absolute;
  top: 1.9rem;
  left: 50%;
  transform: translate(-50%, 0);
  width: 10.6rem;
  height: 0.8rem;
  line-height: 0.8rem;
  color: #121212;
  font-size: 0.3rem;
  background: #ffffff;
  border: 0.02rem solid #a2a3a3;
  border-radius: 0.8rem;
  padding: 0 0.3rem;
  box-sizing: border-box;
  background: #ffffff;
}
.skills-main-box .skills-banner-box .select-box .select-box-rel {
  position: relative;
  width: 100%;
  height: 100%;
}
.skills-main-box .skills-banner-box .select-box .select-box-rel .left-img {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 0.34rem;
  height: 0.34rem;
  right: 0.3rem;
  cursor: pointer;
}
.skills-main-box .skills-banner-box .select-box .select-box-rel .right-input {
  width: 80%;
  height: 100%;
  line-height: 0.8rem;
}
.skills-main-box
  .skills-banner-box
  .select-box
  .select-box-rel
  .right-input
  input {
  outline: none;
  width: 100%;
  background: none;
}
.swiper-wrapper{
    background: #f5f5f6;
}
</style>